<template>
  <section id="dashboard-analytics">
    <b-row v-if="getDetailsFinancial">
      <b-col cols="12">
        <b-card no-body>
          <div class="m-2">
            <b-row>
              <b-col md="3" class="position-relative">
                <div>
                  <b-media vertical-align="center">
                    <template #aside>
                      <b-avatar
                        size="50"
                        v-if="getDetailsFinancial.photo"
                        :src="
                          getDetailsFinancial.photo.full_path +
                          getDetailsFinancial.photo.file_name
                        "
                      />
                      <b-avatar size="36" v-else />
                    </template>
                    <p class="m-0" dir="ltr"> اسم المركز </p>
                    <b-card-title class="mb-0 mt-1"> {{ getDetailsFinancial.name }}</b-card-title>
                  </b-media>
                </div>
              </b-col>
              <b-col md="3" class="position-relative">
                <div>
                  <b-media vertical-align="center">
                    <p class="m-0" dir="ltr"> رقم الهاتف </p>
                    <b-card-title class="mb-0 mt-1"> {{ getDetailsFinancial.phone }}</b-card-title>
                  </b-media>
                </div>
              </b-col>
              <b-col md="3" class="position-relative">
                <div>
                  <b-media vertical-align="center">
                    <p class="m-0" dir="ltr"> تاريخ الإنضمام  </p>
                    <b-card-title class="mb-0 mt-1"> {{ getFormatDate(getDetailsFinancial.created_at) }}</b-card-title>
                  </b-media>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
      <b-col md="4">
        <b-card no-body>
          <div class="m-2">
            <b-row>
              <b-col cols="12" class="position-relative">
                <b-card-title class="mb-0"> إجمالي القبض </b-card-title>
                <div class="dashboard-card">
                  <dashboard-bookings-2-icon />
                  <h3>{{ getDetailsFinancial.total_revenue }} ريال سعودي</h3>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
      <b-col md="4">
        <b-card no-body>
          <div class="m-2">
            <b-row>
              <b-col cols="12" class="position-relative">
                <b-card-title class="mb-0"> إجمالي المديونية </b-card-title>
                <div class="dashboard-card">
                  <dashboard-financial-icon />
                  <h3>{{ getDetailsFinancial.total_revenue_platform }} ريال سعودي</h3>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
      <b-col md="4">
        <b-card no-body>
          <div class="m-2">
            <b-row>
              <b-col cols="12" class="position-relative">
                <b-card-title class="mb-0">إجمالي المتبقي </b-card-title>
                <div class="dashboard-card">
                  <dashboard-bookings-2-icon />
                  <h3>{{ getDetailsFinancial.remaining_amount }} ريال سعودي</h3>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12" md="12">
        <b-card no-body>
          <div class="m-2">
            <b-row>
              <b-col cols="12" class="position-relative">
                <b-card-title class="mb-0"> سجل حساب المركز</b-card-title>
              </b-col>
              <b-col cols="12">
                <!-- Table Container Card -->
                <b-card no-body>
                  <div class="m-2">
                    <!-- Table Top -->
                    <b-row>
                      <!-- Search -->
                      <b-col cols="12" md="12" class="px-0">
                        <div class="d-flex align-items-center justify-content-start">
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              <feather-icon icon="SearchIcon" />
                            </b-input-group-prepend>
                            <b-form-input
                              v-model="filterData.search"
                              class="d-inline-block mr-1 px-0 border-radius-right-0"
                              placeholder="بــــــــــحـــــــــث..."
                              @input="applyFilter"
                            />
                          </b-input-group>
                        </div>
                      </b-col>
                    </b-row>
                  </div>

                  <b-table
                    v-if="getDetailsFinancial.financials"
                    :items="getDetailsFinancial.financials"
                    responsive
                    :fields="tableColumns"
                    :per-page="filterData.per_page"
                    primary-key="id"
                    show-empty
                    empty-text="لا يوجد أي بيانات"
                    class="position-relative"
                  >
                    <!-- Column: created_at -->
                    <template #cell(created_at)="data">
                      <p class="m-0" dir="ltr">{{ getFormatDate(data.value) }}</p>
                    </template>

                    <!-- Column: amount -->
                    <template #cell(amount)="data">
                      <p class="m-0">{{ data.value }} ر.س</p>
                    </template>
                  </b-table>
                  <div
                    v-if="
                      getDetailsFinancial.financials &&
                      getDetailsFinancial.financials.length > 0
                    "
                    class="mx-2 mb-2"
                  >
                    <b-row>
                      <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                      >
                        <label>إدخالات</label>
                        <v-select
                          v-model="filterData.per_page"
                          :dir="'rtl'"
                          :options="perPageOptions"
                          :clearable="false"
                          :searchable="false"
                          class="per-page-selector d-inline-block ml-50 mr-1"
                          style="width: 15%"
                          @input="handlePerPageChange($event)"
                        />
                        <span class="text-muted"
                          >عرض {{ 1 }} إلى {{ filterData.per_page }} من
                          {{ getTotalDetailsFinancial.totalItems }} إدخالات</span
                        >
                      </b-col>
                      <!-- Pagination -->
                      <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                      >
                        <b-pagination
                          v-model="filterData.page"
                          :total-rows="getTotalDetailsFinancial.totalItems"
                          :per-page="filterData.per_page"
                          first-number
                          last-number
                          class="mb-0 mt-1 mt-sm-0"
                          prev-class="prev-item"
                          next-class="next-item"
                          @change="handlePageChange"
                        >
                          <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                          </template>
                          <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                          </template>
                        </b-pagination>
                      </b-col>
                    </b-row>
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import {
  BCard,
  BCardTitle,
  BCardSubTitle,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    vSelect,
    BInputGroup,
    BFormGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
  },
  data() {
    return {
      filterData: {
        center_id: this.$router.currentRoute.params.id,
        page: 1,
        per_page: 10,
        order_by: "desc",
        search: "",
      },
      perPageOptions: [10, 25, 50, 100],
      tableColumns: [
        { key: "id", label: "#", sortable: false },
        { key: "deposit_number", label: "رقم السند", sortable: false },
        { key: "amount", label: "المبلغ المدفوع", sortable: false },
        { key: "payment_type", label: "نوع الدفع", sortable: false },
        { key: "created_at", label: "تاريخ الدفع", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getDetailsFinancial: "getDetailsFinancial",
      getTotalDetailsFinancial: "getTotalDetailsFinancial",
    }),
  },
  created() {},
  methods: {
    ...mapActions({
      loadDetailsFinancial: "loadDetailsFinancial",
    }),
    applyFilter() {
      this.loadDetailsFinancial(this.filterData);
    },
    handlePageChange(value) {
      this.filterData.page = value;
      this.loadDetailsFinancial(this.filterData);
    },
    handlePerPageChange(value) {
      this.loadDetailsFinancial(this.filterData);
    },
    getFormatDate(date) {
      const d = new Date(date);
      const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
      const mo = new Intl.DateTimeFormat("en", { month: "numeric" }).format(d);
      const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
      return `${ye}-${mo}-${da}`;
    },
  },
  mounted() {
    this.loadDetailsFinancial(this.filterData);
  },
};
</script>
